import { useState } from 'react';
import { MdWarning } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { Class } from 'src/models/classes';
import { User } from 'src/models/user';
import StudentPassWarning from 'src/models/StudentPassWarning';
import CreateModal from './CreateModal';
import StudentWarningAlert from './StudentWarningAlert';

interface StudentWarningSectionProps{
  class: Class;
  student: User;
  warning?: StudentPassWarning;
  teacher?: User;
  supervisor?: User;
}

export default function StudentWarningSection({ class: _class, student, warning, teacher, supervisor }: StudentWarningSectionProps) {
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  if (!warning?.submittedAt) {
    return (
      <>
        <div
          className="justify-center flex mt-8"
        >
          <button
            aria-label={formatMessage({ id: 'send-warning' })}
            onClick={() => setShowModal(true)}
            className="text-black bg-white py-2 px-4 rounded-full shadow-tableShadow flex"
            data-testid="send-warning-button"
          >
            <MdWarning className="text-secondary self-center mr-2" />
            <FormattedMessage id="send-warning" />
          </button>
        </div>
        <CreateModal isOpen={showModal} close={() => setShowModal(false)} class={_class} student={student} supervisor={supervisor} teacher={teacher} comment={warning?.comment ?? ''} passRequirement={warning?.passRequirement ?? ''} passRequirementSummary={warning?.passRequirementSummary ?? ''} />
      </>
    );
  }

  return <StudentWarningAlert warning={warning} student={student} class={_class} supervisor={supervisor} teacher={teacher} />;
}
